import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "react-tabs/style/react-tabs.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Style.css";
import Galleryimg1 from "../../assets/img/galleryimg1.webp";
import Galleryimg2 from "../../assets/img/galleryimg2.webp";
import Galleryimg3 from "../../assets/img/galleryimg3.webp";
import Galleryimg4 from "../../assets/img/galleryimg4.webp";
import Galleryimg5 from "../../assets/img/galleryimg5.webp";
import Galleryimg6 from "../../assets/img/galleryimg6.webp";
import Galleryimg7 from "../../assets/img/galleryimg7.webp";
import Galleryimg8 from "../../assets/img/galleryimg8.webp";
import Galleryimg9 from "../../assets/img/galleryimg9.webp";
import Galleryimg10 from "../../assets/img/galleryimg10.webp";
import Galleryimg11 from "../../assets/img/galleryimg11.webp";
import Galleryimg12 from "../../assets/img/galleryimg12.webp";
const TestimonialSlider = () => {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isHovered7, setIsHovered7] = useState(false);
  const [isHovered8, setIsHovered8] = useState(false);
  const [isHovered9, setIsHovered9] = useState(false);
  const [isHovered10, setIsHovered10] = useState(false);
  const [isHovered11, setIsHovered11] = useState(false);
  const [isHovered12, setIsHovered12] = useState(false);
  const [isHovered13, setIsHovered13] = useState(false);
  const [isHovered14, setIsHovered14] = useState(false);
  const [isHovered15, setIsHovered15] = useState(false);
  const [isHovered16, setIsHovered16] = useState(false);
  const [isHovered17, setIsHovered17] = useState(false);

  const handleMouseEnter = (containerNumber) => {
    if (containerNumber === 1) {
      setIsHovered1(true);
    } else if (containerNumber === 2) {
      setIsHovered2(true);
    } else if (containerNumber === 3) {
      setIsHovered3(true);
    } else if (containerNumber === 4) {
      setIsHovered4(true);
    } else if (containerNumber === 5) {
      setIsHovered5(true);
    } else if (containerNumber === 6) {
      setIsHovered6(true);
    } else if (containerNumber === 7) {
      setIsHovered7(true);
    } else if (containerNumber === 8) {
      setIsHovered8(true);
    } else if (containerNumber === 9) {
      setIsHovered9(true);
    } else if (containerNumber === 10) {
      setIsHovered10(true);
    } else if (containerNumber === 11) {
      setIsHovered11(true);
    } else if (containerNumber === 12) {
      setIsHovered12(true);
    } else if (containerNumber === 13) {
      setIsHovered13(true);
    } else if (containerNumber === 14) {
      setIsHovered14(true);
    } else if (containerNumber === 15) {
      setIsHovered15(true);
    } else if (containerNumber === 16) {
      setIsHovered16(true);
    } else if (containerNumber === 17) {
      setIsHovered17(true);
    }
    // Add more conditions for additional containers if needed
  };

  const handleMouseLeave = (containerNumber) => {
    if (containerNumber === 1) {
      setIsHovered1(false);
    } else if (containerNumber === 2) {
      setIsHovered2(false);
    } else if (containerNumber === 3) {
      setIsHovered3(false);
    } else if (containerNumber === 4) {
      setIsHovered4(false);
    } else if (containerNumber === 5) {
      setIsHovered5(false);
    } else if (containerNumber === 6) {
      setIsHovered6(false);
    } else if (containerNumber === 7) {
      setIsHovered7(false);
    } else if (containerNumber === 8) {
      setIsHovered8(false);
    } else if (containerNumber === 9) {
      setIsHovered9(false);
    } else if (containerNumber === 10) {
      setIsHovered10(false);
    } else if (containerNumber === 11) {
      setIsHovered11(false);
    } else if (containerNumber === 12) {
      setIsHovered12(false);
    } else if (containerNumber === 13) {
      setIsHovered13(false);
    } else if (containerNumber === 14) {
      setIsHovered14(false);
    } else if (containerNumber === 15) {
      setIsHovered15(false);
    } else if (containerNumber === 16) {
      setIsHovered16(false);
    } else if (containerNumber === 17) {
      setIsHovered17(false);
    }
    // Add more conditions for additional containers if needed
  };

  const location = useLocation();
  const isWorksPage = location.pathname === "/works";
  const isHomePage = location.pathname === "/";

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabSelect = (index) => {
    setTabIndex(index);
  };

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
        <div className="container">
          <TabList
            className={`md:flex lg:flex  gap-5 ${
              isWorksPage
                ? "lg:justify-center md:justify-center justify-center"
                : isHomePage
                ? "lg:justify-end md:justify-end justify-center"
                : "lg:justify-end md:justify-end justify-center"
            }`}
          >
            <Tab className="custom-tab-list">
              <button
                style={{
                  backgroundColor: tabIndex === 0 ? "" : "",
                  color: tabIndex === 0 ? "white" : "",
                }}
                className={`btncasestudies ${
                  tabIndex === 0 ? "active" : ""
                } lg:w-auto md:w-auto w-full  m-auto `}
              >
                Social media
              </button>
            </Tab>
            <Tab className="custom-tab-list">
              <button
                style={{
                  backgroundColor: tabIndex === 1 ? "" : "",
                  color: tabIndex === 1 ? "white" : "",
                }}
                className={`btncasestudies ${
                  tabIndex === 1 ? "active" : ""
                } lg:w-auto md:w-auto w-full  m-auto`}
              >
                professional photography
              </button>
            </Tab>
            <Tab className="custom-tab-list">
              <button
                style={{
                  backgroundColor: tabIndex === 2 ? "" : "",
                  color: tabIndex === 2 ? "white" : "",
                }}
                className={`btncasestudies ${
                  tabIndex === 2 ? "active" : ""
                } lg:w-auto md:w-auto w-full  m-auto`}
              >
                professional Videography
              </button>
            </Tab>
          </TabList>
        </div>

        <TabPanel className="mt-5">
          <div className="container">
            <div className="lg:w-[90%] m-auto ">
              <div className="lg:grid md:grid grid-cols-2 ">
                <img src={Galleryimg1} alt="galleryimg" />
                <div className="lg:grid md:grid grid-rows-3 lg:w-[100%] grid-flow-col lg:ml-4">
                  <img
                    src={Galleryimg2}
                    alt="galleryimg"
                    className="lg:mt-0 md:mt-0 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg3}
                    alt="galleryimg"
                    className="lg:mt-1 md:mt-1 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg4}
                    alt="galleryimg"
                    className="lg:mt-2 md:mt-3 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg5}
                    alt="galleryimg"
                    className="lg:mt-0 md:mt-0 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg5}
                    alt="galleryimg"
                    className="lg:mt-1 md:mt-1 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg6}
                    alt="galleryimg"
                    className="lg:mt-2 md:mt-3 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                </div>
              </div>
            </div>
            <div className="lg:w-[90%] m-auto ">
              <div className="lg:grid md:grid grid-cols-2  mt-[20px] ">
                <div className="lg:grid md:grid grid-rows-2 lg:w-[100%] grid-flow-col">
                  <img
                    src={Galleryimg7}
                    alt="galleryimg"
                    className="lg:mt-0 md:mt-0 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  ></img>
                  <img
                    src={Galleryimg8}
                    alt="galleryimg"
                    className="lg:mt-2 md:mt-1 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  ></img>
                  <img
                    src={Galleryimg11}
                    alt="galleryimg"
                    className="lg:mt-0 md:mt-0 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  ></img>
                  <img
                    src={Galleryimg12}
                    alt="galleryimg"
                    className="lg:mt-2 md:mt-1 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  ></img>
                </div>

                <img
                  src={Galleryimg9}
                  alt="galleryimg"
                  className="lg:mt-0 md:mt-1 mt-0"
                ></img>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="container">
            <div className="lg:w-[90%] m-auto ">
              <div className="lg:grid md:grid grid-cols-2   ">
                <div className="lg:grid md:grid grid-rows-2 lg:w-[100%] grid-flow-col">
                  <img
                    src={Galleryimg7}
                    alt="galleryimg"
                    className="lg:mt-0 md:mt-0 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  ></img>
                  <img
                    src={Galleryimg8}
                    alt="galleryimg"
                    className="lg:mt-2 md:mt-1 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  ></img>
                  <img
                    src={Galleryimg11}
                    alt="galleryimg"
                    className="lg:mt-0 md:mt-0 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  ></img>
                  <img
                    src={Galleryimg12}
                    alt="galleryimg"
                    className="lg:mt-2 md:mt-1 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  ></img>
                </div>

                <img
                  src={Galleryimg9}
                  alt="galleryimg"
                  className="lg:mt-0 md:mt-1 mt-0"
                ></img>
              </div>
            </div>
            <div className="lg:w-[90%] m-auto ">
              <div className="lg:grid md:grid grid-cols-2 mt-[20px]">
                <img src={Galleryimg1} alt="galleryimg" />
                <div className="lg:grid md:grid grid-rows-3 lg:w-[100%] grid-flow-col lg:ml-4">
                  <img
                    src={Galleryimg2}
                    alt="galleryimg"
                    className="lg:mt-0 md:mt-0 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg3}
                    alt="galleryimg"
                    className="lg:mt-1 md:mt-1 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg4}
                    alt="galleryimg"
                    className="lg:mt-2 md:mt-3 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg5}
                    alt="galleryimg"
                    className="lg:mt-0 md:mt-0 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg5}
                    alt="galleryimg"
                    className="lg:mt-1 md:mt-1 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg6}
                    alt="galleryimg"
                    className="lg:mt-2 md:mt-3 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="container">
            <div className="lg:w-[90%] m-auto ">
              <div className="lg:grid md:grid grid-cols-2 ">
                <img src={Galleryimg1} alt="galleryimg" />
                <div className="lg:grid md:grid grid-rows-3 lg:w-[100%] grid-flow-col lg:ml-4">
                  <img
                    src={Galleryimg2}
                    alt="galleryimg"
                    className="lg:mt-0 md:mt-0 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg3}
                    alt="galleryimg"
                    className="lg:mt-1 md:mt-1 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg4}
                    alt="galleryimg"
                    className="lg:mt-2 md:mt-3 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg5}
                    alt="galleryimg"
                    className="lg:mt-0 md:mt-0 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg5}
                    alt="galleryimg"
                    className="lg:mt-1 md:mt-1 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                  <img
                    src={Galleryimg6}
                    alt="galleryimg"
                    className="lg:mt-2 md:mt-3 mt-5 lg:w-auto md:w-auto w-full object-cover md:object-contain lg:object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
      {/* <div className="container">
        <div className="flex gap-2 justify-end items-center">
          <img src={Left} alt="left"></img>
          <p className="text-white font-bold text-[15px] uppercase">Drag</p>
          <img src={Right} alt="right"></img>{" "}
        </div>
      </div> */}
    </>
  );
};

export default TestimonialSlider;
