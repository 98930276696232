import React, { useState } from "react";
import Abouticon from "../../assets/img/abouticon.svg";
import Flagicon1 from "../../assets/img/flagicon1.svg";
import { Link } from "react-router-dom";
import Flagicon2 from "../../assets/img/flagicon2.svg";
import "../contactform/Style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import emailjs from "@emailjs/browser";
import Footer from "../Footer";
function Contactform() {
  const [errors, setErrors] = useState({});
  console.log("errors", errors);
  const notify = () => toast("Thank you for contact. We will contact Shortly");
  const showToast = (message, type = "success") => {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000, // Close the toast after 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const [formData, setFormData] = useState({
    name: "",
    jobTitle: "",
    companyName: "",
    email: "",
    phonenumber: "", // renamed from phonenumber
    message: "",
    subscribe: false,
    select: "", // added select field
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = (e) => {
    console.log("caaaaaaling", formData);
    e.preventDefault();
    // Perform validation checks
    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    }
    if (!formData.jobTitle.trim()) {
      validationErrors.jobTitle = "Job Title is required";
    }
    if (!formData.companyName.trim()) {
      validationErrors.companyName = "Company Name is required";
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = "Invalid email format";
    }
    if (!formData.phonenumber.trim()) {
      validationErrors.phonenumber = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.phonenumber)) {
      validationErrors.phonenumber = "Invalid phone number";
    }
    if (!formData.select.trim()) {
      validationErrors.select = "Please select a service";
    }
    if (!formData.message.trim()) {
      validationErrors.message = "Message is required";
    }
    if (!formData.subscribe) {
      validationErrors.subscribe = "Invalid subscribe value";
    }
    // If validation errors exist, set them and return
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    // If no validation errors, submit the form
    emailjs
      .sendForm(
        "service_200omdg",
        "template_xhkb3bq",
        formData.current,
        "p87NmtLp7L4bobmxM"
      )
      .then(
        (response) => {
          console.log("Email sent successfully:", response);
          showToast("Email sent successfully", "success");
        },
        (error) => {
          console.error("Error sending email:", error);
          showToast("Error sending email", "error");
        }
      );
  };

  return (
    <>
      <div className="conatiner">
        <div className="text-white lg:w-full md:w-[490px] lg:text-[69px] lg:mt-[100px] md:mt-[80px] mt-[40px] md:text-[89px] text-[40px] font-semibold uppercase">
          LETS GET IN TOUCH
        </div>
     
        <div className="lg:flex gap-[0px] mt-[50px] lg:justify-between">
        <div>
            <p className="text-white text-[21px] font-semibold lg:mt-0 md:mt-[50px] mt-[50px] mb-5 uppercase">
              CONTACT US
            </p>
            <form ref={formData} onSubmit={handleSubmit}>
              <ToastContainer />
              <div className="form-row lg:flex md:flex  gap-5">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="inputfield"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <div>
                    {errors.name && (
                      <span className="text-red-500">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div className="lg:mt-0 md:mt-0 mt-5 form-group">
                  <input
                    type="text"
                    placeholder="Job Titel"
                    id="jobTitle"
                    className="inputfield"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                  />
                  <div>
                    {errors.jobTitle && (
                      <span className="text-red-500">{errors.jobTitle}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-rowlg:flex md:flex  mt-5 gap-5">
                <div className="form-group">
                  <input
                    type="text"
                    id="companyName"
                    className="inputfield"
                    name="companyName"
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={handleChange}
                  />{" "}
                  <div>
                    {errors.companyName && (
                      <span className="text-red-500">{errors.companyName}</span>
                    )}
                  </div>
                </div>
                <div className=" lg:mt-0 md:mt-0 mt-5 form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="inputfield"
                    value={formData.email}
                    onChange={handleChange}
                  />{" "}
                  <div>
                    {errors.email && (
                      <span className="text-red-500">{errors.email}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-row lg:flex md:flex  mt-5 gap-5">
                <div className="form-group">
                  <input
                    type="text"
                    id="phonenumber"
                    className="inputfield"
                    name="phonenumber"
                    placeholder="Phone Number"
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                  <div>
                    {errors.phonenumber && (
                      <span className="text-red-500">{errors.phonenumber}</span>
                    )}
                  </div>
                </div>
                <div className="lg:mt-0 md:mt-0 mt-5 form-group">
                  <div className="custom-select">
                    <select
                      id="select"
                      name="select"
                      className="inputfield"
                      value={formData.select}
                      onChange={handleChange}
                    >
                      <option value="">Select Service</option>
                      <option value="marketing">Marketing</option>
                      <option value="support">Support</option>
                    </select>
                    <div>
                      {errors.select && (
                        <span className="text-red-500">{errors.select}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <textarea
                    id="message"
                    name="message"
                    className="inputfieldtextarea"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <div>
                    {errors.message && (
                      <span className="text-red-500">{errors.message}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-row ">
                <div className="form-group  lg:w-[559px] md:w-[559px]">
                  <input
                    type="checkbox"
                    id="subscribe"
                    name="subscribe"
                    checked={formData.subscribe}
                    onChange={handleChange}
                    className="text-red-500"
                  />

                  <label
                    htmlFor="subscribe"
                    className="text-white text-base font-normal ml-5"
                  >
                    Privacy Policy - We help you in sharing your video content
                    related to your product or services hassle-free. We help you
                    in sharing your video content related to your
                  </label>
                </div>
                <div>
                  {errors.subscribe && (
                    <span className="text-red-500">{errors.subscribe}</span>
                  )}
                </div>
              </div>
              <div className="form-row mt-5 ">
                <button type="submit" className="submitbtn">
                Get a Quote
                </button>
              </div>
            </form>
          </div>
          <div className="lg:mt-0 md:mt-0 mt-10">
          <div className="borderClr p-5">
              <img src={Flagicon1} alt="flagicon"></img>
              <p className="text-white text-[24px] font-medium mt-5">
                Metasoft IT Solution{" "}
              </p>
              <p className="text-white text-[18px] font-normal pt-3 lg:w-[412px] md:w-[407px]">
                Office No. 216A, PO Box No. 90071, Ittihad Square, Al Ittihad
                Road , Dubai, UAE
              </p>
              <div className="flex flex-wrap gap-5 pt-5">
                <div className=" flex gap-5">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.62 11.29C8.06 14.12 10.38 16.43 13.21 17.88L15.41 15.68C15.68 15.41 16.08 15.32 16.43 15.44C17.55 15.81 18.76 16.01 20 16.01C20.55 16.01 21 16.46 21 17.01V20.5C21 21.05 20.55 21.5 20 21.5C10.61 21.5 3 13.89 3 4.5C3 3.95 3.45 3.5 4 3.5H7.5C8.05 3.5 8.5 3.95 8.5 4.5C8.5 5.75 8.7 6.95 9.07 8.07C9.18 8.42 9.1 8.81 8.82 9.09L6.62 11.29Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-white text-[14px] font-normal">
                    +971 4 399 1704
                  </p>
                </div>
                <div className="flex gap-5">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 4.5H4C2.9 4.5 2.01 5.4 2.01 6.5L2 18.5C2 19.6 2.9 20.5 4 20.5H20C21.1 20.5 22 19.6 22 18.5V6.5C22 5.4 21.1 4.5 20 4.5ZM20 8.5L12 13.5L4 8.5V6.5L12 11.5L20 6.5V8.5Z"
                      fill="white"
                    />
                  </svg>
                  <Link to="mailto:sales@metasoft.ae"> <p className="text-white curser-pointer text-[14px] font-normal">
                    admin@metasoft.com
                  </p></Link>
                </div>
              </div>
            </div>
            <div className="mt-[50px] borderbtm p-5">
              <img src={Flagicon2} alt="flagicon"></img>

              <p className="text-white text-[24px] mt-5 font-medium">
                Metasoftit Infotech Solutions{" "}
              </p>
              <p className="text-white text-[18px] font-normal pt-3 lg:w-[412px] md:w-[407px]">
                NO.13-1-T/14,3RD FLOOR- EXCELMALL, K.S.RAO ROAD, HAMPANKATTA,
                MANGALURU, KARNATAKA, INDIA
              </p>
              <div className="flex flex-wrap gap-5 pt-5">
                <div className="flex gap-5">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.62 11.29C8.06 14.12 10.38 16.43 13.21 17.88L15.41 15.68C15.68 15.41 16.08 15.32 16.43 15.44C17.55 15.81 18.76 16.01 20 16.01C20.55 16.01 21 16.46 21 17.01V20.5C21 21.05 20.55 21.5 20 21.5C10.61 21.5 3 13.89 3 4.5C3 3.95 3.45 3.5 4 3.5H7.5C8.05 3.5 8.5 3.95 8.5 4.5C8.5 5.75 8.7 6.95 9.07 8.07C9.18 8.42 9.1 8.81 8.82 9.09L6.62 11.29Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-white text-[14px] font-normal">
                    +971 4 399 1704
                  </p>
                </div>
                <div className="flex gap-5">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 4.5H4C2.9 4.5 2.01 5.4 2.01 6.5L2 18.5C2 19.6 2.9 20.5 4 20.5H20C21.1 20.5 22 19.6 22 18.5V6.5C22 5.4 21.1 4.5 20 4.5ZM20 8.5L12 13.5L4 8.5V6.5L12 11.5L20 6.5V8.5Z"
                      fill="white"
                    />
                  </svg>
                  <Link to="mailto:sales@metasoft.ae"> <p className="text-white curser-pointer text-[14px] font-normal">
                    admin@metasoft.com
                  </p></Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <p className="text-white text-[21px] font-semibold lg:mt-0 md:mt-[50px] mt-[50px] mb-5 uppercase">
              CONTACT US
            </p>
            <form ref={formData} onSubmit={handleSubmit}>
              <ToastContainer />
              <div className="form-row lg:flex md:flex  gap-5">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="inputfield"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <div>
                    {errors.name && (
                      <span className="text-red-500">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div className="lg:mt-0 md:mt-0 mt-5 form-group">
                  <input
                    type="text"
                    placeholder="Job Titel"
                    id="jobTitle"
                    className="inputfield"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                  />
                  <div>
                    {errors.jobTitle && (
                      <span className="text-red-500">{errors.jobTitle}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-rowlg:flex md:flex  mt-5 gap-5">
                <div className="form-group">
                  <input
                    type="text"
                    id="companyName"
                    className="inputfield"
                    name="companyName"
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={handleChange}
                  />{" "}
                  <div>
                    {errors.companyName && (
                      <span className="text-red-500">{errors.companyName}</span>
                    )}
                  </div>
                </div>
                <div className=" lg:mt-0 md:mt-0 mt-5 form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="inputfield"
                    value={formData.email}
                    onChange={handleChange}
                  />{" "}
                  <div>
                    {errors.email && (
                      <span className="text-red-500">{errors.email}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-row lg:flex md:flex  mt-5 gap-5">
                <div className="form-group">
                  <input
                    type="text"
                    id="phonenumber"
                    className="inputfield"
                    name="phonenumber"
                    placeholder="Phone Number"
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                  <div>
                    {errors.phonenumber && (
                      <span className="text-red-500">{errors.phonenumber}</span>
                    )}
                  </div>
                </div>
                <div className="lg:mt-0 md:mt-0 mt-5 form-group">
                  <div className="custom-select">
                    <select
                      id="select"
                      name="select"
                      className="inputfield"
                      value={formData.select}
                      onChange={handleChange}
                    >
                      <option value="">Select Service</option>
                      <option value="marketing">Marketing</option>
                      <option value="support">Support</option>
                    </select>
                    <div>
                      {errors.select && (
                        <span className="text-red-500">{errors.select}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <textarea
                    id="message"
                    name="message"
                    className="inputfieldtextarea"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <div>
                    {errors.message && (
                      <span className="text-red-500">{errors.message}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-row ">
                <div className="form-group  lg:w-[559px] md:w-[559px]">
                  <input
                    type="checkbox"
                    id="subscribe"
                    name="subscribe"
                    checked={formData.subscribe}
                    onChange={handleChange}
                    className="text-red-500"
                  />

                  <label
                    htmlFor="subscribe"
                    className="text-white text-base font-normal ml-5"
                  >
                    Privacy Policy - We help you in sharing your video content
                    related to your product or services hassle-free. We help you
                    in sharing your video content related to your
                  </label>
                </div>
                <div>
                  {errors.subscribe && (
                    <span className="text-red-500">{errors.subscribe}</span>
                  )}
                </div>
              </div>
              <div className="form-row mt-5 ">
                <button type="submit" className="submitbtn">
                Get a Quote
                </button>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Contactform;
