import React from "react";
import Header from "../header/Header";
import "../about/Style.css";
import Footer from "../Footer";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Aboutframe from "../../assets/img/aboutframe.webp";
import AboutVector from "../../assets/img/aboutimg.webp";
import img1 from "../../assets/img/img1.webp"
import img2 from "../../assets/img/img2.webp"
import img3 from "../../assets/img/img3.webp"
import img4 from "../../assets/img/img4.webp"
import img5 from "../../assets/img/img5.webp"
import Abouticon from "../../assets/img/abouticon.svg";
import Marqueelogo1 from "../../assets/img/marqueelogo1.webp";
import Marqueelogo2 from "../../assets/img/marqueelogo2.webp";
import Marqueelogo3 from "../../assets/img/marqueelogo3.webp";
import Marqueelogo4 from "../../assets/img/marqueelogo4.webp";
import Marqueelogo5 from "../../assets/img/marqueelogo5.webp";
import Dmteammember1 from "../../assets/img/dmteammember1.webp";
import Dmteammember2 from "../../assets/img/dmteammember2.webp";
import Aboutheaderbg from "../../assets/img/aboutheaderbg.webp";
import Dmteammember3 from "../../assets/img/ahamadyogi.svg";
import Dmteammember4 from "../../assets/img/shail.svg";
import Dmteammember5 from "../../assets/img/aliyogi.svg";
import Aboutmetasfotlogo from "../../assets/img/aboutmetasfotlogo.webp";
import Contactform from "../contactform/Contactform";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgaboutfirstsection from "../../assets/img/aboutbg.webp"
function About() {
  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="bg-[#0B0920]">
        <div className="bg-orange-600 ">
          <div className="bannerGrd">
          <div className='bgHeaderabout'>
                <div className="container">
                    <Header />
                </div>
                </div>
              
              <div className="flex justify-center h-[60vh] items-center">
                <img
                  src={Aboutheaderbg}
                  alt="aboutframe"
                  className="lg:mt-[80px] md:mt-[00%] mt-[%]"
                ></img>
              
            </div>
          </div>
        </div>
       <div className="bgimgabout">
<div className="container">
<div className="flex justify-center ">
  <div className="pt-[100px]">
    <div className="flex justify-center items-center  ">
<img src={Aboutmetasfotlogo} alt="img"  className="lg:w-[50%] md:w-[50%] w-[300px]"/>
</div>
<p className="textabout text-white mt-[0px] lg:text-[100px] md:text-[100px] text-[50px] lg:mt-[-70px] md:mt-[-70px] mt-[-50px] lg:tracking-[15px] md:tracking-[15px] tracking-[5px] text-center font-light">We Are
 <span className="font-extrabold uppercase text-center"> metasoft</span></p>
</div>
</div>
</div>
       </div>
     
        <div className="container">

        <div className=" lg:mt-[100px] md:mt-[80px] mt-[40px]">
      <div className="">
        <div className="lg:grid md:grid gap-8">
          <div className="lg:flex md:flex md:grid gap-5">
            <div>
          <p className="text-white lg:text-[35px] md:text-[35px] text-[30px] text-left m-0 p-0 font-light  ">
                  {" "}
                 Our <span className="font-semibold">Story</span>
                </p>
                <div className="lg:ml-[70px] md:ml-[70px] mt-[30px]">
                <svg width="252" height="157" viewBox="0 0 302 157" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 2C37.0831 39.6658 125.823 113.678 200.116 108.399C292.983 101.801 89.9141 17.2588 72.5789 75.8194C58.7108 122.668 218.415 148.127 300 155" stroke="#F05F22" stroke-width="4" stroke-linecap="round"/>
</svg>
</div></div>
<div>
            <p className="text-[#DFDCFF] text-[20px] lg:w-[806px] text-start font-light">
              From <span className="font-semibold">one man team to a small team of young people</span> who know exactly
              the new school methods of marketing and how to help businesses grow.
              We know that people these days look for genuine communication with
              the brand as humans.
            </p>
            <div className="flex justify-between mt-[30px] md:ml-10 lg:ml-10">
            <img src={img1} alt="Image 1" className="w-1/3" />
            <img src={img2} alt="Image 1" className="w-1/3" />
            <img src={img3} alt="Image 1" className="w-1/3" />
          </div>
            </div>
          </div>
          </div>

      
      </div>
      <div className="lg:mt-[100px] md:mt-[80px] mt-[60px]">
        
      <div className="lg:flex md:flex justify-between">
        
<div>
<p className="text-white lg:hidden md:hidden block lg:text-[35px] md:text-[35px] text-left text-[30px]   m-0 p-0 font-light  ">
                  {" "}
                 Our <span className="font-semibold">Vision</span>
                </p>  <div className="lg:hidden md:hidden block lg:ml-[70px] md:ml-[70px] mt-[30px]">
                <svg width="252" height="157" viewBox="0 0 302 157" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 2C37.0831 39.6658 125.823 113.678 200.116 108.399C292.983 101.801 89.9141 17.2588 72.5789 75.8194C58.7108 122.668 218.415 148.127 300 155" stroke="#F05F22" stroke-width="4" stroke-linecap="round"/>
</svg>
</div>
                
            <p className="text-[#DFDCFF] text-[20px] text-left font-light">
            Our vision is to become the leader in modernizing businesses through creative and genuine marketing. We strive to blend innovative strategies with authentic connections, paving the way for lasting relationships between businesses and their audiences.
            </p>
            <div className="flex justify-between mt-[50px] lg:mr-[30px] md:mr-[30px] ">
            <img src={img4} alt="Image 1" />
          
          </div>
            </div>
            <div>
          <p className="text-white lg:block md:block hidden lg:text-[35px] md:text-[35px] text-left text-[30px] text-right  m-0 p-0 font-light  ">
                  {" "}
                 Our <span className="font-semibold">Vision</span>
                </p>
                <div className="lg:ml-[50px] md:ml-[50px] lg:block md:block hidden mt-[30px]">
                <svg width="252" height="157" viewBox="0 0 302 157" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M300 2C264.917 39.6658 176.177 113.678 101.884 108.399C9.01662 101.801 212.086 17.2588 229.421 75.8194C243.289 122.668 83.5854 148.127 2 155" stroke="#F05F22" stroke-width="4" stroke-linecap="round"/>
</svg>

</div></div>
      
          </div>

      
      </div>
      <div className="lg:mt-[100px] md:mt-[80px] mt-[60px]">
        <div className="lg:grid md:grid gap-8">
          <div className="lg:flex md:flex gap-5">
            <div>
          <p className="text-white lg:text-[35px] md:text-[35px] text-[30px] text-left m-0 p-0 font-light  ">
                  {" "}
                 Our <span className="font-semibold">Mission</span>
                </p>
                <div className="lg:ml-[70px] md:ml-[70px] mt-[30px]">
                <svg width="252" height="157" viewBox="0 0 302 157" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 2C37.0831 39.6658 125.823 113.678 200.116 108.399C292.983 101.801 89.9141 17.2588 72.5789 75.8194C58.7108 122.668 218.415 148.127 300 155" stroke="#F05F22" stroke-width="4" stroke-linecap="round"/>
</svg>
</div></div>
<div>
            <p className="text-[#DFDCFF] text-[20px] lg:w-[806px] text-start font-light">
            Our mission is to provide businesses with a unique service that is different from agencies which we act as your in-house team not external agency
            </p>
            <div className="flex justify-between mt-[30px] lg:ml-10 md:ml-10">
            <img src={img5} alt="Image 1" />
           
          </div>
            </div>
          </div>
          </div>

      
      </div>
    </div>
          
        </div>
        <div className="lg:mt-[100px]  md:mt-[100px] mt-[100px] lg:mb-[50px] md:mb-[0px] mb-[100px]">
          <Marquee pauseOnHover speed={100}>
            <div className="image_wrapper">
              <img src={Marqueelogo1} alt="marqueimg1"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo2} alt="marqueimg2"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo3} alt="marqueimg3"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo4} alt="marqueimg4"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo5} alt="marqueimg5"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo1} alt="marqueimg1"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo2} alt="marqueimg2"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo3} alt="marqueimg3"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo4} alt="marqueimg4"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo5} alt="marqueimg5"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo1} alt="marqueimg1"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo2} alt="marqueimg2"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo3} alt="marqueimg3"></img>
            </div>
            <div className="image_wrapper">
              
              <img src={Marqueelogo4} alt="marqueimg4"></img>
            </div>
            <div className="image_wrapper">
              <img src={Marqueelogo5} alt="marqueimg5"></img>
            </div>
          </Marquee>
        </div>
        <div>
          <div className="container">
            <p className="text-white lg:mt-[50px] md:mt-[50px] mt-[0px] lg:text-[57px] md:text-[57px] text-[40px] font-black">
              OUR TEAM
            </p>
          </div>
          <div className="container">
            <Slider {...sliderSettings}>
              
              <div className="element element-2">
                <img className="image" src={Dmteammember2} alt="Image 2" />
                <div className="hover-text text-white">
                  <p className="text-[24px] font-medium">Alicia </p>
                  <p className="text-[18px] font-normal">Graphic Designer</p>
                </div>
              </div>
              <div className="element element-3">
                <img className="image" src={Dmteammember3} alt="Image 2" />
                <div className="hover-text text-white">
                  <p className="text-[24px] font-medium">Ahmad Yaghi </p>
                  <p className="text-[18px] font-normal">Digital Marketing Director</p>
                </div>
              </div>
              <div className="element element-4">
                <img className="image" src={Dmteammember4} alt="Image 1" />
                <div className="hover-text text-white">
                  <p className="text-[24px] font-medium">sahil</p>
                  <p className="text-[18px] font-normal">Videographer </p>
                </div>
              </div>
              <div className="element element-5">
                <img className="image" src={Dmteammember5} alt="Image 2" />
                <div className="hover-text text-white">
                  <p className="text-[24px] font-medium">Ali Yaghi </p>
                  <p className="text-[18px] font-normal">Sales Manager</p>
                </div>
              </div>
           
              {/* Add more elements as needed */}
            </Slider>

            {/* <Contactform /> */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default About;
