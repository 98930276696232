import React from "react";
import Header from "../header/Header";
import "../contact/Style.css";
import Footer from "../Footer";
import Contactframe from "../../assets/img/contactframe.webp";
import Map from "../../assets/img/map.webp";
import Contactform from "../contactform/Contactform";
import Meetingimg from "../../assets/img/meetingimg.webp";
import { InlineWidget } from "react-calendly";
import ContactFrame from "../../assets/img/contactframe.webp"
function Contact() {
  return (
    <>
      <div className="bg-[#0B0920]">
      <div className="bg-orange-600 z-10">
          <div className="bannerGrd z-10">
          <div className='bgHeaderabout z-10'>
                <div className="container z-10">
                    <Header />
                </div>
                </div>
              
              <div className="flex justify-center h-[60vh] items-center">
                <img
                  src={ContactFrame}
                  alt="aboutframe"
                  className="lg:mt-[80px] md:mt-[00%] mt-[%]"
                ></img>
              
            </div>
          </div>
        </div>
        <div className="container">
        {/* <div className="lg:mt-[150px] md:mt-[150px] mt-[50px]">
            <p className=" lg:w-[490px] leading-snug md:w-[490px] lg:mt-[80px] md:mt-0 mt-5 text-white font-semibold uppercase lg:text-[69px] md:text-[50px] text-[47px]">
              SCHEDULE A MEETING
            </p>
            <div className="mt-[50px] w-100 flex justify-center">
              <InlineWidget url="https://calendly.com/metasoftinfotech/15?primary_color=ff2e84" />
            </div>
          </div> */}
          <img src={Map} alt="map" className="mt-[100px]"/>
          <Contactform />
      
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Contact;
