import React from 'react';
import Video from "../../assets/video/website header.mp4";
import "../home/Style.css";
import Bannerimg from "../../assets/img/Metasoft.svg";
import Header from "../header/Header";

function Bgvideo() {
    return (
        <div className='bgContainer '>
            <div className='overlay  border-b-[10px] border-solid border-[#F05F22] rounded-b-[48px]'></div>
            <video autoPlay loop muted className='video-background rounded-b-[48px]'>
                <source src={Video} type="video/mp4" />
                <source src={Video} type="video/ogg" />
                Your browser does not support the video tag.
            </video>
            <div className='homeHeader'>
                <div className='bgHeader'>
                <div className="container">
                    <Header />
                </div>
                </div>
            </div>
            <div className='bgBannercontent'>
                <div className="container">
                    <img
                        src={Bannerimg}
                        alt="bannerimg"
                        className="banneroverlay"
                    />
                </div>
            </div>
        </div>
    );
}

export default Bgvideo;
