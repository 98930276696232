// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./components/home/Home";
import About from "./components/about/About"
import Works from "./components/works/Works";
import Contact from "./components/contact/Contact";
import Privacy from "./components/PrivacyPolicypage"
function App() {
  return (
    <div className="App">
      <Router>
     
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/works" element={<Works />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
