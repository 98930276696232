import React from 'react'
import Header from "./header/Header";
import Footer from "./Footer";
function PrivacyPolicypage() {
  return (
   <>
      <div className="bg-[#0B0920]">
      
          <div className="container">
            <Header  />
    <h1 className='font-semibold lg:text-[79px] text-[69px]  mt-[40px] text-white'>Privacy policy</h1>
<div className='mt-[40px]'>
<div><p className='text-semibold text-[18px] text-white'>Effective Date: [Insert Date]
    </p>
    <p className='text-[18px] font-normal text-white mt-[30px] lg:w-[838px]'>[Company Name] ("we," "us," "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines the types of personal information we collect, how we use and protect it, and your choices regarding the collection and use of your information.</p>
    </div>
    <div className='mt-[60px]'>
        <p className='font-semibold text-[57px] text-white'>Information Collection and Use</p>
        <p className='text-[18px] font-medium text-white mt-5'>We collect and use personal information for the following purposes:</p>
        <ul className='font-normal list-disc ml-5 text-[18px] mt-[30px] text-white'>
            <li>To provide and maintain our services.</li>
            <li>
To notify you about changes to our services.</li>
<li>
To allow you to participate in interactive features of our services when you choose to do so.</li>
<li>
To provide customer support.</li>
<li>
To gather analysis or valuable information so that we can improve our services.</li>
<li>
To monitor the usage of our services.</li>
<li>
To detect, prevent and address technical issues.</li>
        </ul>
    </div>
    <div className='mt-[60px]'>
        <p className='font-semibold text-[57px] text-white'>Types of Data Collected</p>
        <p className='font-semibold text-[18px] text-white mt-4'>Personal Data</p>
        <p className='text-[18px] font-medium text-white mt-5 lg:w-[838px]'>While using our services, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
        <ul className='font-normal list-disc ml-5 text-[18px] mt-[30px] text-white'>
            <li>Email address</li>
            <li>
            First name and last name</li>
<li>
To allow you to participate in interactive features of our services when you choose to do so.</li>
<li>
Phone number.</li>
<li>
Address, State, Province, ZIP/Postal code, City.</li>
<li>
Cookies and Usage Data</li>

        </ul>
    </div>
    <div className='mt-[60px]'>
    <p className='font-semibold text-[18px] text-white mt-4'>Usage Data</p>
        <p className='text-[18px] font-medium text-white mt-5 lg:w-[838px]'>We may also collect information about how the services are accessed and used ("Usage Data").
</p>
    </div>
</div>
           
            </div>
            <Footer/>
            </div>
          
   
   </>
  )
}

export default PrivacyPolicypage