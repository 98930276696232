import React from "react";
import Header from "../header/Header";
import "./Style.css";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import TabSlider from "../../components/tabslider/TabSlider";
import Workframe from "../../assets/img/WorkFrame.webp"
function Works() {
  return (
    <>
      <div className="bg-[#0B0920]">
      <div className="bg-orange-600 ">
          <div className="bannerGrd">
          <div className='bgHeaderabout'>
                <div className="container">
                    <Header />
                </div>
                </div>
              
              <div className="flex justify-center h-[60vh] items-center">
                <img
                  src={Workframe}
                  alt="aboutframe"
                  className="lg:mt-[80px] md:mt-[00%] mt-[%]"
                ></img>
              
            </div>
          </div>
        </div>  
       
            
        <div className="container">
        <div className="bgimgwork">
            <div className="lg:flex   lg:mt-[70px] md:mt-[50px] mt-5">
              <div>
                <p className="text-white lg:text-[55px] leading-[60px] lg:h-[400px] md:h-[400px] lg:w-[690px] md:text-[57px] text-[40px]  m-0 p-0 font-extrabold uppercase ">
                  {" "}
                  making difference through design
                </p>
              </div>
              <div className="lg:mt-[100px] lg:ml-[-80px] lg:block md:block hidden ">
              <svg width="203" height="191" viewBox="0 0 253 191" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.4635 0.636851C2.71065 -0.171416 1.44512 -0.216344 0.636851 0.536502C-0.171416 1.28935 -0.216344 2.55488 0.536502 3.36315L3.4635 0.636851ZM169.452 100.164L169.969 102.096L169.452 100.164ZM61.2929 60.6114L59.3161 60.9146L61.2929 60.6114ZM0.536502 3.36315C16.0457 20.0141 38.3728 50.1927 66.5101 73.2684C94.7523 96.4301 129.405 112.956 169.969 102.096L168.934 98.2322C130.158 108.614 96.8252 92.9569 69.0466 70.1755C41.1632 47.308 19.4345 17.7836 3.4635 0.636851L0.536502 3.36315ZM169.969 102.096C176.796 100.268 181.092 97.1586 181.753 92.5005C182.071 90.2643 181.475 87.9906 180.344 85.8062C179.212 83.6197 177.483 81.4019 175.332 79.1992C166.784 70.4435 150.554 60.9074 133.118 53.5444C115.668 46.1756 96.621 40.8188 82.3147 40.678C75.1945 40.6078 68.8967 41.8235 64.6068 45.089C60.1331 48.4945 58.2274 53.8172 59.3161 60.9146L63.2698 60.3082C62.349 54.3049 63.9813 50.5922 67.0296 48.2718C70.2616 45.8115 75.4695 44.6107 82.2753 44.6778C95.8211 44.8111 114.307 49.9429 131.562 57.2293C148.831 64.5216 164.478 73.8076 172.47 81.9935C174.455 84.0261 175.902 85.9262 176.792 87.6455C177.684 89.3669 177.957 90.7871 177.793 91.9382C177.493 94.0546 175.428 96.4936 168.934 98.2322L169.969 102.096ZM59.3161 60.9146C61.6127 75.8874 70.1689 92.7187 82.7065 109.093C95.2675 125.497 111.936 141.588 130.669 155.079C168.02 181.978 214.137 198.914 252.515 186.504L251.284 182.699C214.737 194.516 170.002 178.476 133.006 151.833C114.566 138.553 98.1877 122.731 85.8824 106.661C73.5539 90.56 65.4243 74.3545 63.2698 60.3082L59.3161 60.9146Z" fill="#F05F22"/>
</svg>

              </div>
            <div className="lg:mt-[200px] lg:block md:block hidden lg:ml-[20px]">
            <svg width="168" height="145" viewBox="0 0 168 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M77.7331 117.169L145.643 75.7757C146.706 75.1274 146.244 73.4917 144.998 73.4961L84.3022 73.7101C83.5832 76.2573 81.2509 78.1304 78.4718 78.1402C75.1116 78.1519 72.3781 75.4372 72.3665 72.0771C72.3549 68.7169 75.0686 65.9834 78.4288 65.9709C81.2598 65.961 83.6396 67.8896 84.3281 70.5057L145.173 70.2918C146.4 70.2873 146.864 68.6873 145.831 68.0266L76.8638 23.9244C76.5253 23.7077 76.1018 23.6701 75.7303 23.8241L57.7834 35.3443C48.6062 41.2347 38.2786 45.1034 27.4899 46.69L16.7021 48.2774C16.2419 48.4681 15.941 48.9184 15.9437 49.4171L16.0959 92.6231C16.0977 93.1271 16.4066 93.5784 16.8757 93.7628L29.0898 94.9473C39.4524 95.9528 49.4165 99.4544 58.1299 105.153L76.6435 117.263C77.0016 117.404 77.4054 117.369 77.7331 117.169Z" fill="white"/>
<path d="M0.000547316 93.7684L0.000549309 48.1702C0.000549315 48.0242 0.118745 47.9061 0.264684 47.9061L11.1116 47.9061C11.2575 47.9061 11.3757 48.0242 11.3757 48.1702L11.3757 93.7684C11.3757 93.9144 11.2575 94.0326 11.1116 94.0326L0.264682 94.0326C0.118743 94.0326 0.000547309 93.9135 0.000547316 93.7684Z" fill="white"/>
<path d="M136.67 138.268C166.959 100.406 165.382 66.5909 158.726 44.8885C151.497 21.3208 136.89 6.50585 136.743 6.3596L138.153 4.94957C138.303 5.09949 153.217 20.207 160.607 44.2217C167.432 66.4014 169.088 100.937 138.227 139.514L136.67 138.268Z" fill="#8E8AC5"/>
<path d="M135.933 7.73908C133.797 7.73908 132.064 6.00712 132.064 3.86954C132.064 1.73288 133.796 -2.6258e-07 135.933 -1.69143e-07C138.07 -7.57465e-08 139.803 1.73196 139.803 3.86954C139.802 6.00712 138.07 7.73908 135.933 7.73908Z" fill="#8E8AC5"/>
<path d="M135.933 142.76C133.797 142.76 132.064 141.028 132.064 138.89C132.064 136.753 133.796 135.021 135.933 135.021C138.07 135.021 139.803 136.752 139.803 138.89C139.802 141.028 138.07 142.76 135.933 142.76Z" fill="#8E8AC5"/>
<path d="M167.039 75.5403L167.039 68.9933L160.492 68.9933L160.492 75.5403L167.039 75.5403Z" fill="#8E8AC5"/>
<path d="M168 144.933L168 138.386L161.453 138.386L161.453 144.933L168 144.933Z" fill="#8E8AC5"/>
<path d="M167.12 8.92514L167.12 2.37811L160.573 2.37811L160.573 8.92514L167.12 8.92514Z" fill="#8E8AC5"/>
<path d="M165.701 141.47L164.85 6.48276L162.856 6.49532L163.707 141.482L165.701 141.47Z" fill="#8E8AC5"/>
</svg>

            </div>
            </div>
          </div>
        </div>
         
        <div>
        
            <div className="lg:mt-[30px] md:mt-[70px] ">
              <TabSlider />
            </div>
            {/* <div className="container">
            <div className="gallery mt-[80px]">
              <img src={Galleryimg1} alt="galleryimg"></img>
              <img src={Galleryimg2} alt="galleryimg"></img>
              <img src={Galleryimg3} alt="galleryimg"></img>
              <img src={Galleryimg4} alt="galleryimg"></img>
              <img src={Galleryimg5} alt="galleryimg"></img>
              <img src={Galleryimg6} alt="galleryimg"></img>
              <img src={Galleryimg7} alt="galleryimg"></img>
              <img src={Galleryimg8} alt="galleryimg"></img>
              <img src={Galleryimg9} alt="galleryimg"></img>
            </div>
          </div> */}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Works;
