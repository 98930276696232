import React, { useState } from "react";
import "../header/Style.css";

import Logo from "../../assets/img/Logo.svg";
import Menu from "../../assets/img/menu.svg";
import { Link } from "react-router-dom";
import Closebtn from "../../assets/img/closebtn.svg";
import Modelimages from "../../assets/img/modelimages.webp";
import Modal from "react-modal";
function Heder() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="flex justify-between bgcolor items-center">
        <div className="logoMain">
          <Link to="/">
            <img src={Logo} alt="Logo"></img>
          </Link>
        </div>

        <div>
          <img
            src={Menu}
            alt="menu"
            className="cursor-pointer"
            onClick={handleShow}
          ></img>
        </div>
      </div>

      <Modal
        isOpen={show}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
        className="modalbg"
        overlayClassName="modalOverlay" // Add overlayClassName to style the overlay
      >
        <div className="container w-full">
          <div className="modalHeader ">
            <div className="flex items-center justify-between">
              <Link to="/" className="logoMain">
                <img src={Logo} alt="Logo"></img>
              </Link>
              <img
                src={Closebtn}
                alt="closebtn"
                className="cursor-pointer"
                onClick={handleClose}
              ></img>
            </div>
          </div>
          <div className="modalContent">
            <div className="flex flex-wrap justify-evenly items-center">
              <div className="lg:block md:block hidden">
                <img
                  src={Modelimages}
                  alt="modelimages"
                  className="h-[400px]"
                ></img>
              </div>
              <div>
                <ul className="lg:mt-0 md:mt-0 mt-[70px]">
                  <Link to="/">
                    <li className="text-white lg:text-[68px] text-[48px] font-extrabold uppercase hover:text-orange-600 transition ease-in-out ">
                      Home
                    </li>
                  </Link>
                  <Link to="/works">
                    {" "}
                    <li className="text-white lg:text-[68px] text-[48px] font-extrabold uppercase hover:text-orange-600 transition ease-in-out ">
                      Our Work
                    </li>
                  </Link>
                  <Link to="/about">
                    {" "}
                    <li className="text-white lg:text-[68px]  text-[48px] font-extrabold uppercase hover:text-orange-600 transition ease-in-out ">
                      About US
                    </li>
                  </Link>
                  <Link to="/contact">
                    {" "}
                    <li className="text-white lg:text-[68px]  text-[48px] font-extrabold uppercase hover:text-orange-600 transition ease-in-out ">
                      Contact
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
          <div className="modalFooter lg:mt-0 md:mt-0 mt-[100px]">
            <div className="flex  justify-start items-center gap-[20px] lg:gap-[50px] border-t-2 border-[#F05F22] lg:pt-5 md:pt-5 pt-5 ">
              <a
                href="#"
                className="text-white lg:text-[18px] font-normal uppercase hover:text-orange-600 transition ease-in-out "
              >
                linkedin
              </a>
              <a
                href="#"
                className="text-white lg:text-[18px] font-normal uppercase hover:text-orange-600 transition ease-in-out "
              >
                facebook
              </a>
              <Link
                to="https://www.instagram.com/metasoft_dm?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                className="no-underline"
                target="_blank"
              >
                {" "}
                <p className="text-white lg:text-[18px] font-normal uppercase hover:text-orange-600 transition ease-in-out ">
                  instagram
                </p>
              </Link>
              <button
                type="button"
                className="text-white lg:text-[22px] close hover:text-orange-600 transition ease-in-out" // Make sure to remove data-bs-dismiss
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Heder;
